/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/theming";
@import "./theme.scss";

@include mat-core();

$config: mat-typography-config();

$psi-typography: mat-typography-config(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $headline: mat-typography-level(3rem, 4.5rem, 400),
    $body-1: mat-typography-level(1.6rem, 2.4rem, 350),
    $body-2: mat-typography-level(1.6rem, 2.4rem, 350),
);

@include mat-base-typography($psi-typography);

html,
body {
    height: 100%;
    font-size: 62.5%; // 10px
}

// @media (max-width: 700px) {
//     html,
//     body {
//         height: 100%;
//         font-size: 75%; // 12px
//     }
// }

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: #333;
}

.psi-custom-dialog {
    width: 90%;
    & .mat-dialog-container {
        padding: 0rem;
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        cursor: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.cdk-overlay-backdrop-showing {
    cursor: pointer;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.position-center {
    margin: 0px !important;
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
}

.tutorial-element {
    @include mat-typography-level-to-styles($psi-typography, body-1);
    position: absolute;
    display: block;
    z-index: 400;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 1.8rem;
    padding: 3rem;
    margin: 2rem;
    visibility: hidden;
    word-wrap: normal;
    max-width: 45rem;
    white-space: normal;
    mat-icon {
        vertical-align: bottom;
    }
    box-sizing: border-box;
    box-shadow: 0 8px 8px 8px rgba(0, 0, 0, 0.3);
    border: 3px solid #dbab2d;
    outline: 5px solid #fff;
}

.tutorial-blocker {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    // background-color: rgba(#000, 0.5);
    z-index: 200;
    cursor: pointer;
}

mat-form-field {
    width: 100%;
}

.highlighted {
    font-weight: 425;
}

a {
    font-weight: 425;
    text-decoration: none;
}

.no-padding {
    padding: 0rem !important;
}

.notification-badge {
    position: relative;
    top: -1rem;
    left: 0.2rem;
    vertical-align: super;
    border-radius: 50%;
    background: red;
    color: white;
    width: 0.6rem;
    height: 0.6rem;
}

.icon-notification {
    &::after {
        content: "";
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;
        vertical-align: super;
        border-radius: 50%;
        background: red;
        color: white;
        width: 0.6rem;
        height: 0.6rem;
    }
}
